import React from "react";
import {
  Typography,
  AppBar,
  CssBaseline,
  Container,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import "./style.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <>
        <CssBaseline />
        <AppBar
          sx={{ background: "#2f3542", position: "relative", width: "100%" }}
        >
          <div className="header">
            <Tabs className="tabs">
              <Tab label="Home" sx={{ color: "white" }} />
              <Tab label="Services" sx={{ color: "white" }} />
              <Tab label="Contact" sx={{ color: "white" }} />
            </Tabs>

            <IconButton className="button">
              <TranslateIcon />
            </IconButton>
          </div>
        </AppBar>
        <main className="main">
          <div>
            <Container className="introText">
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                sx={{ m: "1", color: "black" }}
              >
                LT Gallo
              </Typography>

              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
                sx={{ color: "black" }}
              >
                test testtest testtest testtest testtest testtest testtest
                testtest testtest testtest testtest testtest testtest testtest
                testtest testtest testtest test
              </Typography>
            </Container>
          </div>
          <img src="businessimage.jpg" alt=""></img>
        </main>

        <div className="footer">
          <div className="div1">
            <p>Contact</p>
            <span>E-Mail: giusy.gallo@outlook.de</span>
            <span>Phone: </span>
            <span>Imprint</span>
          </div>
          <div className="div2">
            <p>Footer</p>
            <span>1</span>
            <span>2</span>
            <span>3</span>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
